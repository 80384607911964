import * as React from "react"
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import * as styles from "./index.module.css"

const HelpBrainTrainingDatasafetyPage = () => {
    return (
        <Layout isTop={false}>
            <Seo title="データの削除方法"
                 description="「毎日 脳トレ」のデータの削除方法はこちらでご覧いただけます。"/>
            <div className={styles.content}>
                <h2>「毎日 脳トレ」のデータの削除方法</h2>
                <p>
                    nullhouseが開発した<a
                    href="https://play.google.com/store/apps/details?id=info.nullhouse.braintraining">毎日 脳トレ
                    1日5分で頭の体操</a>のデータの削除方法について、説明します。
                </p>

                <h3 className={styles.sectionTitle}>機種変更時のデータ引き継ぎをおこなったことがない方</h3>
                <p>アプリをただアンインストールすれば、プレイ履歴などのデータをすべて削除することができます。</p>

                <h3 className={styles.sectionTitle}>機種変更時のデータ引き継ぎをおこなったことがある方</h3>
                <ol className={styles.decimalList}>
                    <li>アプリを起動します。</li>
                    <li>「設定」ボタンをタップします。</li>
                    <li>「機種変更時のデータ引き継ぎ」をタップします。</li>
                    <li>「次へ」ボタンをタップします。</li>
                    <li>「過去に紐づけたデータの削除」をタップします。</li>
                    <li>過去に紐づけたGoogleもしくはAppleのアカウントでログインします。</li>
                </ol>

                <h4>削除されるデータ</h4>
                <ol className={styles.discList}>
                    <li>データ引き継ぎでアップロードしたプレイ履歴のデータ</li>
                    <li>データ引き継ぎをアップロードした際のアプリバージョン、OSバージョン、アップロード日時</li>
                </ol>

                <h4>データ保持期間について</h4>
                <ol className={styles.discList}>
                    <li>データ引き継ぎでアップロードしたプレイ履歴のデータ: アップロードしてから3日間</li>
                    <li>データ引き継ぎをアップロードした際のアプリバージョン、OSバージョン、アップロード日時:
                        アップロードしてから3日間
                    </li>
                </ol>
            </div>
        </Layout>
    )
}

export default HelpBrainTrainingDatasafetyPage
